@import "deps";
@import "fonts";


:root {
  --swiper-navigation-size: 30px;
  --swiper-theme-color: #{$text-color};
}

// reset
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;

  img {
    border: none;
  }

  &:hover {
    text-decoration: none;
    opacity: .8;
  }
}


html, body {
  height: 100%;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background-color: $dark-gray;
  font-family: "ProximaNova", Arial, sans-serif;
  line-height: 1;
  font-size: 0;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

#app-page {
  overflow: hidden;
  min-height: 100vh;
}

#app-main {
  display: flex;
  min-height: inherit;
  flex-direction: column;
  //justify-content: center;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: $grid-max-width;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  @include for-tablet-portrait-up() {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.link {
  color: $purple;
}

.center-text {
  text-align: center;
}

.text-markdown {
  p {
    margin: 0;
  }
}
