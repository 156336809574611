@import "variables";


// вертикальное расстояние между полями
$field-space: 20px;
$field-space-mobile: 15px;

// горизонтальное расстояние между полями
$column-space: 20px;
$column-space-mobile: 15px;


// input text
$input-color: $light;
$placeholder-color: rgba($input_color, .5);
$input-color-error: $red;

// input bg
$input-bg_color: $gray;
$input-bg-error: rgba(233, 68, 68, 0.1);

// // input border
$border-color: $dark-gray;
$border-color-hover: rgba($dark-gray, .4);
$border-color-invalid: $red;
