@import "../deps";


$mobile: 420px;

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.main-page {
  background-color: $darkest-gray;

  .text-block {
    padding: 60px;
    background-color: $dark-gray;

    @include media_interval(0, $media-tablet-portrait) {
      margin-left: -25px;
      margin-right: -25px;
    }
  }

  .top {
    //text-align: center;
  }

  .top-label {
    font-weight: bold;
    font-size: rem(20);
    transform-style: preserve-3d;
    line-height: 1.5;
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;

    @include media_interval($mobile, 0) {
      font-size: rem(23);
    }
    @include for-tablet-portrait-up() {
      font-size: rem(28);
    }
    @include for-tablet-landscape-up() {
      font-size: rem(34);
    }
  }

  .tier {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #211f21;
    box-shadow: 0 12px 10px rgba(#141414, 1);
    font-size: rem(24);
    font-weight: bold;
    position: relative;

    @include for-tablet-portrait-up() {
      font-size: rem(29);
      width: 70px;
      height: 70px;
    }
    @include for-tablet-landscape-up() {
      font-size: rem(34);
      width: 80px;
      height: 80px;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      counter-increment: item;
      content: counter(item);
    }
  }

  .list-item {
    @include media_interval(0, $mobile) {
      @include flex-columns(2, 10px, 20px);
    }
    @include media_interval($mobile, $media-tablet-portrait) {
      @include flex-columns(2, 15px, 25px);
    }
    @include media_interval($media-tablet-portrait, $media-tablet-landscape) {
      @include flex-columns(2, 30px, 30px);
    }
    @include media_interval($media-tablet-landscape, $media-desktop) {
      @include flex-columns(2, 40px, 40px);
    }
    @include media_interval($media-desktop, $media-big-desktop) {
      @include flex-columns(4, 20px, 20px);
    }
    @include media_interval($media-big-desktop, 0) {
      @include flex-columns(4, 40px, 40px);
    }
  }

  .card {
    overflow: hidden;
    position: relative;
    transition: box-shadow 1s $animation-func;

    .card-image-wrapper {
      padding-top: #{100% * ($card_h / $card_w)};
      //padding-top: #{100% * (600 / 450)};

      .card-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        user-select: none;
        //will-change: transform;
        transform: translateZ(0); // fix chrome img blurry
      }
    }

    @at-root .can-hover & {
      &:hover {
        box-shadow: 0 10px 40px rgba(150, 153, 157, 0.5);
      }
    }
  }

  .swiper-container {
    overflow: visible;
    counter-reset: item;
    margin-top: -35px;
  }

  .swiper-slide {
    .card {
      cursor: pointer;
      border-radius: 16px 16px 3px 8px;
      @include for-phone-only() {
        border-radius: 9px 9px 3px 3px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $light;
        animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
      }

      .card-image-wrapper {
        filter: grayscale(100%) opacity(100%);
        transition: filter 1s $animation-func;

        .card-image {
          opacity: 0;

          &.swiper-lazy-loaded {
            opacity: 1;
            transition: opacity $appear-animation-duration $animation-func;
          }
        }
      }

      // states
      &.skipped {
        &:before {
          animation: none;
        }
        .card-image-wrapper {
          filter: grayscale(100%) opacity(60%);
        }
      }

      @at-root .can-hover & {
        &:hover {
          .card-image-wrapper {
            filter: grayscale(0) opacity(100%);
          }
        }
      }

      &.selected {
        .card-image-wrapper {
          filter: grayscale(0) opacity(100%);
        }
      }
    }
  }

  .swiper-ui {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
  }

  .swiper-navigation {
    width: 290px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    transform: translateX(-50%);
  }

  .swiper-button-prev, .swiper-button-next {
    //top: 100%;
  }

  .swiper-pagination {
    pointer-events: auto;
    white-space: nowrap;
    position: relative;
    left: -4px;
  }

  .swiper-pagination-bullet {
    margin: 0 4px;
    width: 8px;
    height: 8px;
    background: $light;
    opacity: .3;
    transition: 0.2s left, 0.2s opacity;

    &.swiper-pagination-bullet-active {
      opacity: 1;
    }

    &.swiper-pagination-bullet-active-prev, &.swiper-pagination-bullet-active-next {
      transform: none;
    }
    &.swiper-pagination-bullet-active-prev-prev, &.swiper-pagination-bullet-active-next-next {
      transform: none;
    }
  }

  .bottom {
    text-align: center;
    @include media_interval(0, $media-tablet-portrait) {
      padding-top: 40px;
    }
  }
}

// slider selected cards
.list-thumbs {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  font-weight: bold;
  font-size: rem(20);

  @include for-tablet-portrait-up() {
    font-size: rem(22);
  }
  @include for-tablet-landscape-up() {
    font-size: rem(24);
  }

  @include media_interval($mobile, $media-tablet-portrait) {
    margin-left: -35px;
    margin-right: -35px;
  }
  @include media_interval(0, $mobile) {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 35px;
  }
}

.thumb-list-item {
  @include media_interval(0, $mobile) {
    @include flex-columns(3, 15px, 22px);
  }
  @include media_interval($mobile, $media-tablet-portrait) {
    @include flex-columns(4, 15px, 30px);
  }
  @include media_interval($media-tablet-portrait, $media-tablet-landscape) {
    @include flex-columns(4, 20px, 40px);
  }
  @include media_interval($media-tablet-landscape, 0) {
    @include flex-columns(7, 10px, 0);
  }
}

.thumb-list-item-tier {
  margin-bottom: 15px;

  @include media_interval(0, $mobile) {
    margin-bottom: 9px;
  }
}

.thumb-list-item-card {
  //border-radius: 9px 9px 3px 3px;
}

.thumb-list-item-placeholder {
  position: relative;
  padding-top: #{100% * ($card_h / $card_w)};

  &:before {
    content: '—';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
}

// user selected cards
.selected-list {
  margin-top: 40px;
  margin-bottom: 60px;
}

.selected-list-item-tier {
  font-weight: bold;
  font-size: rem(20);
  margin-bottom: 15px;
  text-align: center;

  @include for-tablet-portrait-up() {
    font-size: rem(22);
  }
  @include for-tablet-landscape-up() {
    font-size: rem(24);
  }

  @include media_interval(0, $mobile) {
    margin-bottom: 10px;
  }
}

// user cant selected
.empty-label {
  margin-top: 40vh;
  max-width: 252px;
  display: inline-block;
  line-height: 1.3;
  font-size: rem(17);

  @include for-tablet-portrait-up() {
    max-width: 483px;
    font-size: rem(20);
  }
}
