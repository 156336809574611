// @include vpad(40px);
// @include vpad(40px, 30px);
@mixin vpad($vals...) {
  @if length($vals) == 1 {
    padding-top: $vals;
    padding-bottom: $vals;
  } @else if(length($vals) == 2) {
    padding-top: nth($vals, 1);
    padding-bottom: nth($vals, 2);
  } @else {
    @error "vpad allows only one or two arguments. Getted: '#{$vals}'";
  }
}


// @include hpad(40px);
// @include hpad(40px, 30px);
@mixin hpad($vals...) {
  @if length($vals) == 1 {
    padding-left: $vals;
    padding-right: $vals;
  } @else if(length($vals) == 2) {
    padding-left: nth($vals, 1);
    padding-right: nth($vals, 2);
  } @else {
    @error "hpad allows only one or two arguments. Getted: '#{$vals}'";
  }
}
