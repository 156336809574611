@import "../deps";


@keyframes lift {
  0% {
    transform: translateY(100px);
  }
  50% {
    transform: translateY(-180px);
  }
  100% {
    transform: translateY(100px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.preloader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: $dark-gray;

  .preloader {
    position: relative;
    margin: auto;
    width: $ball-size;
    height: $ball-size;
    top: 50%;
    animation: lift 3s cubic-bezier(0.175, 0.885, 0.320, 1.275) infinite reverse; /* easeOutBack */

    @include for-phone-only() {
      width: 60px;
      height: 60px;
    }

    .preloader-ball {
      width: 100%;
      height: 100%;
      animation: spin 3s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite; /* easeOutCirc */
    }
  }
}
